<template>
  <v-data-table
    :headers="headerData"
    :items="containerStatusData"
    dense
    height="50vh"
    hide-default-footer
    disable-pagination
    class="ma-0 pa-4 buyer-table rounded-0"
  >
    <template v-slot:item="{ item }">
      <tr>
        <td
          v-for="(header, key) in headerData"
          :key="key"
          class="text-center pa-0"
        >
          <v-btn
            color="#20212E"
            class="lighten-2 rounded-0"
            block
            v-if="header.value === 'Action'"
            @click="showModal(item, 'Update Container Status')"
          >
            <span>Edit<v-icon dark>mdi-account-edit</v-icon></span>
          </v-btn>
          <span v-else>{{ item[header.value] }} </span>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import {
  header,
  containerStatusData,
} from "@/helpers/mocks/containerstatus.js";
import {
  get_container_statuses,
  update_toggle_container_status,
} from "@/helpers/api/indexv2.js";
import { mapActions, mapMutations } from "vuex";
export default {
  props: ["containerStatuses"],
  data() {},
  computed: {
    headerData() {
      let headerData = [];

      for (let { value, title } of this.header) {
        // console.log(value);
        headerData.push({
          text: title,
          align: "center",
          sortable: true,
          value,
          class: "table-header text-capitalize primary",
        });
      }
      return headerData;
    },
  },
  async created() {
    await this.getContainerStatuses();
  },
  watch: {
    containerStatuses() {
      this.containerStatusData = [];
      this.containerStatuses.map((containerStatus) => {
        this.containerStatusData = [
          ...this.containerStatusData,
          Object.assign({}, containerStatus),
        ];
      });
      console.log("containerstatuses in table-vue");
    },
  },
  data() {
    return { header, containerStatusData: [], switch1: true };
  },
  methods: {
    ...mapMutations(["setSnackbar"]),
    ...mapActions(["getContainerStatuses"]),
    showModal(item, title, paperRow) {
      console.log(item);
      this.$emit("showModal", { item, title, paperRow });
    },
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss"></style>
