<template>
  <v-container class="elevation-5 pa-0 ma-0" fluid>
    <v-row dense class="align-center justify-space-between ma-0 pa-1">
      <v-col class="ma-0 pa-1">
        <v-text-field
          class="ma-1 dark-blue"
          hide-details
          type="text"
          v-model="searchContainerStatus"
          @input="changeSearchValue"
          outlined
          single-line
          clearable
          dense
          :prepend-inner-icon="'mdi-magnify'"
        >
          <template v-slot:label>Search </template>
        </v-text-field>
      </v-col>

      <v-col cols="2" md="2" class="d-flex ma-0 pa-0">
        <v-btn color="#20212E" class="elevation-5 pa-0" @click="add" block>
          <span>Add <v-icon dark>mdi-plus</v-icon></span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return { searchContainerStatus: "" };
  },
  methods: {
    add() {
      this.$emit("addContainerStatus");
    },
    changeSearchValue() {
      this.$emit("changeSearchValue", this.searchContainerStatus);
    },
  },
};
</script>

<style scoped>
.panel-buyer .search-text {
  background-color: #20212e;
}
.panel-buyer button {
  border: solid thin #262737 !important;

  color: #3284f4 !important;
}

/* .v-text-field--outlined >>> fieldset {
  border-color: #3284f4 !important;
} */
</style>
