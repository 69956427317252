import { render, staticRenderFns } from "./MultipleContactsForm.vue?vue&type=template&id=6bd71b77&scoped=true"
import script from "./MultipleContactsForm.vue?vue&type=script&lang=js"
export * from "./MultipleContactsForm.vue?vue&type=script&lang=js"
import style0 from "./MultipleContactsForm.vue?vue&type=style&index=0&id=6bd71b77&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bd71b77",
  null
  
)

export default component.exports