<template>
  <ValidationObserver v-slot="{ invalid }" ref="contact_form">
    <modal width="30%" v-if="show" :show="show" :title="title" class="test">
      <template v-slot:title>
        <div class="d-flex justify-space-between" :style="{ width: '100%' }">
          <span>{{ `${itemData.code} - ${itemData.DepotName}` }}</span>

          <span>{{ title }} </span>
        </div>
      </template>
      <template v-slot>
        <MultipleContactsForm
          :itemData="itemData"
          :flagSave="flagSave"
          :title="title"
          @saveContact="save"
        />
      </template>

      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-row class="ma-0 pa-0">
          <v-col class="ma-1 pa-0">
            <v-btn
              class="elevation-5"
              color="primary"
              text
              @click="flagSave = true"
              block
              :disabled="invalid"
            >
              Save
            </v-btn>
          </v-col>
          <v-col class="ma-1 pa-0">
            <v-btn
              class="elevation-5"
              color="primary"
              text
              @click="closeModal"
              block
            >
              Close
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </modal>
  </ValidationObserver>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import MultipleContactsForm from "@/components/DepotManage/MultipleContactsForm.vue";
import { ValidationObserver } from "vee-validate";

import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    Modal,
    MultipleContactsForm,
    ValidationObserver,
  },
  props: ["show", "itemData", "title", "paperRows"],
  computed: {
    ...mapState(["depotManage"]),
  },
  data() {
    return {
      validate: false,
      flagSave: false,
    };
  },
  methods: {
    ...mapActions({
      saveContact: "moduleDepotManage/saveContact",
      getContacts: "moduleDepotManage/getContacts",
      updateContact: "moduleDepotManage/updateContact",
    }),
    ...mapMutations({
      setContactDepotManage: "setContactDepotManage",
    }),
    async save(contactInfo) {
      this.$refs.contact_form.reset();
      this.flagSave = false;

      if (this.title === "Add New Contact") {
        await this.saveContact({
          ...contactInfo,
          depot_id: this.itemData.DepotId,
        });
      } else {
        await this.updateContact({ ...contactInfo });
        let res = await this.getContacts({ DepotId: this.itemData.depot_id });

        let index = this.depotManage.findIndex(
          (x) => x.DepotId === this.itemData.depot_id,
        );

        this.setContactDepotManage({ index, newState: res });
        this.closeModal();
      }

      if (this.itemData.hasOwnProperty("contacts")) {
        let res = await this.getContacts(this.itemData);
        let index = this.depotManage.findIndex(
          (x) => x.DepotId === this.itemData.DepotId,
        );

        this.setContactDepotManage({ index, newState: res });
      }
    },

    closeModal() {
      this.$emit("showModal");
    },
  },
};
</script>

<style scoped lang="scss">
button {
  border: thin #20212e solid;
}

.test {
  .toolbar {
    color: green;
  }
}
</style>
