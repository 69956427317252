<template>
  <v-data-table
    :headers="headerData"
    :items="items"
    dense
    hide-default-footer
    disable-pagination
    class="overflow-y-auto elevation-5 main-table table-readOnly"
    fixed-header
    height="100%"
    mobile-breakpoint="0"
    show-expand
    item-key="group"
  >
    <template v-slot:expanded-item="{ headers, item }">
      <td
        :colspan="headers.length"
        style="padding: 20px !important; max-height: 500px"
        class="elevation-0"
      >
        <v-data-table
          :headers="headerDataExpanded"
          :items="itemsGrouped[`${item.Depot}_${item.Size}_${item.Type}`]"
          dense
          hide-default-footer
          disable-pagination
          class="overflow-y-auto elevation-5 table-readOnly"
          fixed-header
          height="30vh"
          mobile-breakpoint="0"
        ></v-data-table>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from "vuex";
import { generateHeaderData } from "@/utils/tables.js";

export default {
  async created() {
    try {
      const response = await this.getDepotAllSaleHold();
      this.headerData = generateHeaderData([
        { text: "Depot", value: "Depot" },
        { text: "Size", value: "Size" },
        { text: "Type", value: "Type" },
      ]);
      this.headerDataExpanded = generateHeaderData([
        // { text: "Depot", value: "Depot" },
        // { text: "Size", value: "Size" },
        // { text: "Type", value: "Type" },
        { text: "Containers", value: "EquipmentID" },
      ]);
      this.itemsGrouped = response.reduce((acc, item) => {
        const key = `${item.Depot}_${item.Size}_${item.Type}`;
        acc[key] = acc[key] ? [...acc[key], item] : [item];
        return acc;
      }, {});

      this.items = Object.values(this.itemsGrouped).map((x) => {
        return { ...x[0], group: `${x[0].Depot}_${x[0].Size}_${x[0].Type}` };
      });
    } catch (error) {}
  },
  data() {
    return {
      headerData: [],
      headerDataExpanded: [],
      items: [],
      itemsGrouped: [],
    };
  },

  methods: {
    ...mapActions({
      getDepotAllSaleHold: "moduleDepotManage/getDepotAllSaleHold",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
