<template>
  <v-data-table
    dense
    item-key="id"
    light
    hide-default-footer
    class="elevation-5 rounded-0 pa-0 ma-0 overflow-y"
    height="75vh"
    fixed-header
    :headers="header"
    :items="lists"
    :items-per-page="-1"
    expand-icon="mdi-file-tree"
    :show-expand="expanded"
  >
    <template v-slot:[`item.actions`]="{ item }">
      <v-row style="gap: 5px">
        <v-col cols="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                v-bind="attrs"
                v-on="on"
                @click="$router.push({ path: `/customerlist/${item.id}`, meta: item })"
                color="primary"
                class="lighten-2 rounded-0"
                block
                icon
              >
                <v-icon dark>mdi-account-edit</v-icon>
              </v-btn>
            </template>
            <span>Edit List</span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                v-bind="attrs"
                v-on="on"
                @click="deleteList(item)"
                color="error"
                class="lighten-2 rounded-0"
                block
                icon
              >
                <v-icon dark>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete List</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" style="padding: 20px !important">
        <v-row class="fill-height">
          <v-col cols="12" class="my-sticky-header-table">
            <v-data-table
              dense
              item-key="id"
              light
              hide-default-footer
              class="elevation-5 rounded-0 pa-0 ma-0 fill-height overflow-y-auto"
              fixed-header
              height="100%"
              :headers="headerCustomers"
              :items="item.customers"
              :items-per-page="-1"
            />
          </v-col>
        </v-row>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { generateHeaderData } from "@/utils/tables.js";

import { mapActions, mapState } from "vuex";
export default {
  props: ["expanded"],
  async created() {
    this.loading = true;

    this.loading = false;
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState({
      lists: (state) => state.moduleCustomerDistribution.lists,
    }),
    headerCustomers() {
      const HEADER = [
        {
          text: "Customer Description",
          value: "organization_descr",
        },
        {
          text: "Email",
          value: "primary_email",
        },
      ];
      return generateHeaderData(HEADER);
    },
    header() {
      const HEADER = [
        {
          text: "Name of list",
          value: "name",
        },
        {
          text: "",
          value: "actions",
          width: "10%",
        },
      ];
      return generateHeaderData(HEADER);
    },
  },
  methods: {
    ...mapActions({
      deleteListStore: "moduleCustomerDistribution/deleteList",
    }),
    async deleteList(item) {
      await this.deleteListStore(item.id);
    },
  },
};
</script>

<style lang="scss">
.my-sticky-header-table {
  max-height: 500px;
  height: 100%;
}
</style>
