<template>
  <v-data-table
    :headers="headerData"
    :items="depotManage"
    height="70vh"
    dense
    hide-default-footer
    disable-pagination
    class="overflow-y-auto elevation-5 main-table table-readOnly"
    show-expand
    expand-icon="mdi-account-multiple"
    @item-expanded="clickExpanded"
  >
    <template v-slot:[`item.Action`]="{ item }">
      <v-row>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                v-bind="attrs"
                v-on="on"
                @click="showModal({ item, title: 'Update Depot' })"
                color="#20212E"
                class="lighten-2 rounded-0 elevation-5"
                block
                icon
                x-small
              >
                <v-icon dark>mdi-account-edit</v-icon>
              </v-btn>
            </template>
            <span>Edit Depot</span>
          </v-tooltip>
        </v-col>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                v-bind="attrs"
                v-on="on"
                @click="showModal({ item, title: 'Multiple Contacts' })"
                color="#20212E"
                class="lighten-2 rounded-0 elevation-5"
                block
                icon
                x-small
              >
                <v-icon dark>mdi-account-multiple-plus</v-icon>
              </v-btn>
            </template>
            <span>Add Multiple Contacts</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </template>
    <template v-slot:[`item.Depot_Status`]="{ item }">
      {{ capitalize(item.Depot_Status) }}
      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="pa-0 ma-0 ml-3"
            icon
            x-small
            v-on="on"
            @click="changeStatus(item)"
          >
            <v-icon> mdi-swap-horizontal </v-icon>
          </v-btn>
        </template>
        <span
          >Changes status to
          {{
            item.Depot_Status.toLowerCase() === "active" ? "Inactive" : "Active"
          }}</span
        >
      </v-tooltip> -->
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" style="padding: 20px !important">
        <ContactTable :item="item" @showModal="showModal" /></td
    ></template>
  </v-data-table>
</template>

<script>
import { header } from "@/helpers/mocks/depotmanage.js";

import { generateHeaderData } from "@/utils/tables.js";
import Table from "@/components/General/Table.vue";
import ContactTable from "@/components/DepotManage/ContactTable.vue";
import { mapActions, mapMutations } from "vuex";
import { DateTime } from "luxon";
import _ from "lodash";
import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";

export default {
  props: ["depotManage"],
  components: {
    Table,
    ContactTable,
  },
  computed: {
    headerData() {
      return generateHeaderData(this.header);
    },
  },

  data() {
    return { header, depotManageData: [], switch1: true };
  },
  methods: {
    // ...mapMutations(["setSnackbar", "setSpinnerShow", "setDepotManage"]),
    ...mapMutations(["setSnackbar", "setSpinnerShow", "setDepotManage"]),
    ...mapActions({
      getContacts: "moduleDepotManage/getContacts",
      saveDepotDate: "moduleDepotManage/saveDepotDate",
      updateDepotDate: "moduleDepotManage/updateDepotDate",
      getAllManageDepot: "getAllManageDepot",
    }),

    // async changeStatus(item) {
    //   const Vue = this;
    //   if (item.Depot_Status.toLowerCase() !== "active") {
    //     await this.updateDepotDate({
    //       data: {
    //         DepotId: item.DepotId,
    //         date_from: parseFromIsoToFormat(
    //           new Date().toISOString(),
    //           "yyyy-LL-dd",
    //         ),
    //         date_to: undefined,
    //       },
    //       cb: () =>
    //         new Promise((resolve, reject) => {
    //           setTimeout(async function () {
    //             await Vue.getAllManageDepot();
    //             resolve();
    //           }, 500);
    //         }),
    //     });
    //   } else {
    //     let yesterday = new Date();
    //     yesterday.setDate(yesterday.getDate() - 1);
    //     let bYesterday = new Date();
    //     bYesterday.setDate(bYesterday.getDate() - 2);
    //     await this.updateDepotDate({
    //       data: {
    //         DepotId: item.DepotId,
    //         date_from: parseFromIsoToFormat(
    //           bYesterday.toISOString(),
    //           "yyyy-LL-dd",
    //         ),
    //         date_to: parseFromIsoToFormat(
    //           yesterday.toISOString(),
    //           "yyyy-LL-dd",
    //         ),
    //       },
    //       cb: () =>
    //         new Promise((resolve, reject) => {
    //           setTimeout(async function () {
    //             await Vue.getAllManageDepot();
    //             resolve();
    //           }, 500);
    //         }),
    //     });
    //   }
    // },
    showModal({ item, title }) {
      this.$emit("showModal", { item, title });
    },
    truncateDateTime(dt) {
      return new DateTime.fromFormat(dt, "yyyy-MM-dd HH:mm:ss").toFormat(
        "yyyy-LL-dd",
      );
    },
    async clickExpanded({ item }) {
      if (!item.hasOwnProperty("contacts")) {
        console.log("anything");
        let res = await this.getContacts(item);

        this.$set(item, "contacts", res);
        console.log(item["contacts"]);
      }
    },
    capitalize(str) {
      return _.capitalize(str);
    },
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss"></style>
