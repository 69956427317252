<template>
  <v-data-table
    :headers="headerData"
    :items="depotTerminations"
    dense
    hide-default-footer
    disable-pagination
    class="overflow-y-auto elevation-5 main-table table-readOnly"
    fixed-header
    height="100%"
    :header-props="{ sortIcon: 'mdi-chevron-up' }"
  >
    <template v-slot:item="{ item }">
      <tr>
        <td
          v-for="(header, key) in headerData"
          :key="key"
          class="text-center pa-0 td-item"
        >
          <v-btn
            color="#20212E"
            class="lighten-2 rounded-0"
            block
            v-if="header.value === 'Action'"
            @click="showModal(item, 'Update Depot Termination')"
          >
            <span>Edit<v-icon dark>mdi-account-edit</v-icon></span>
          </v-btn>

          <span v-else-if="header.value === 'PassthroughAll'">
            <v-icon v-if="item[header.value] == 'Y'" color="green"
              >mdi-checkbox-marked</v-icon
            ><v-icon v-else color="red">mdi-minus-box</v-icon></span
          >
          <span v-else>
            {{
              header.value === "EffectiveFrom" || header.value === "EffectiveTo"
                ? parseDate(item[header.value])
                : item[header.value]
            }}
          </span>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { header } from "@/helpers/mocks/depottermination.js";
import { mapActions, mapMutations } from "vuex";
import { DateTime } from "luxon";
import { generateHeaderData } from "@/utils/tables.js";
import { parseDate } from "@/helpers/mocks/dates";
export default {
  props: ["depotTerminations"],

  computed: {
    headerData() {
      return generateHeaderData(this.header);
    },
  },
  async created() {
    await this.getDepotTerminations();
  },
  data() {
    return { header, depotTerminationData: [], switch1: true };
  },
  methods: {
    parseDate,
    ...mapMutations(["setSnackbar"]),
    ...mapActions(["getDepotTerminations"]),
    showModal(item, title) {
      this.$emit("showModal", { item, title });
    },
    truncateDateTime(dt) {
      return new DateTime.fromFormat(dt, "yyyy-MM-dd HH:mm:ss").toFormat("yyyy-LL-dd");
    },
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss"></style>
