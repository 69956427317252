<template>
  <form>
    <v-card
      class="ma-0 pa-2 pt-3 pb-0text-end rounded-0 elevation-5"
      outlined
      color="#20212E"
    >
      <v-row class="flex-column">
        <v-col>
          <ValidationProvider
            rules="required"
            name="Contact Name"
            v-slot="{ errors }"
          >
            <v-text-field
              label="* Contact Name"
              class="text-center ma-0 py-3"
              outlined
              dense
              v-model="contactInfo.contact_name"
              :hide-details="!errors[0]"
              :error-messages="errors[0]"
            >
            </v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col>
          <ValidationProvider
            rules="required"
            name="Contact Title"
            v-slot="{ errors }"
          >
            <v-text-field
              label="* Contact Title"
              class="text-center ma-0 py-2"
              outlined
              dense
              v-model="contactInfo.contact_title"
              :hide-details="!errors[0]"
              :error-messages="errors[0]"
            >
            </v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col>
          <ValidationProvider
            rules="required"
            name="Contact Email"
            v-slot="{ errors }"
          >
            <v-text-field
              label="* Contact Email"
              v-model="contactInfo.contact_email"
              class="text-center ma-0 py-2"
              outlined
              dense
              :hide-details="!errors[0]"
              :error-messages="errors[0]"
            >
            </v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col>
          <ValidationProvider
            rules="required"
            name="Contact Phone"
            v-slot="{ errors }"
          >
            <v-text-field
              label="* Contact Phone"
              class="text-center ma-0 py-2"
              outlined
              dense
              v-model="contactInfo.contact_phone"
              :hide-details="!errors[0]"
              :error-messages="errors[0]"
            >
            </v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
    </v-card>
  </form>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: ["flagSave", "title", "itemData"],
  created() {
    if (this.title === "Update Contact") {
      this.contactInfo = Object.assign({}, this.itemData);
    }
  },
  data() {
    return {
      contactInfo: {
        contact_name: "",
        contact_title: "",
        contact_email: "",
        contact_phone: "",
      },
    };
  },
  watch: {
    flagSave() {
      if (this.flagSave) {
        let data = { ...this.contactInfo };
        this.$emit("saveContact", { ...data });

        this.contactInfo = {
          contact_name: "",
          contact_title: "",
          contact_email: "",
          contact_phone: "",
        };
      }
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #3284f4;
}
</style>
