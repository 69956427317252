<template>
  <v-form ref="ContainerStatusForm" v-model="formValid">
    <v-row class="ma-0 pa-0" v-for="(DataRow, index) in paperRows" :key="index">
      <v-col class="ma-0 pa-2 pt-3 pb-0">
        <v-card class="ma-auto rounded-0 elevation-5" outlined color="#20212E">
          <div
            class="text-overline ma-0 pr-4"
            v-show="paperRows.length == totalOfPaperRowsDefault"
          >
            {{ DataRow.title }}
          </div>

          <v-row class="ma-0 pa-1 row-paper">
            <v-col
              class="ma-0 pa-0"
              v-for="(field, index) in DataRow.fields"
              :key="index"
            >
              <template v-if="field.value == 'Mode'">
                <label>{{ field.title }}</label>
                <v-select
                  style="padding: 5px"
                  class="parent-status-dropdown"
                  v-model="selectMode"
                  :items="itemsMode"
                  solo
                  outlined
                  single-line
                  filled
                  dense
                  @change="selectChangedMode"
                >
                </v-select>
              </template>

              <template v-if="field.value == 'step_id'">
                <label>{{ field.title }}</label>
                <v-select
                  style="padding: 5px"
                  class="parent-status-dropdown"
                  v-model="selectParentStatus"
                  :items="itemsParentStatus"
                  item-text="name"
                  item-value="id"
                  solo
                  outlined
                  return-object
                  single-line
                  filled
                  dense
                  @change="selectChanged"
                >
                </v-select>
              </template>

              <template
                v-if="field.value !== 'step_id' && field.value !== 'Mode'"
              >
                <label>{{ field.title }}</label>
                <v-text-field
                  :type="field.value == 'SequenceNo' ? 'number' : 'text'"
                  oninput="this.value=this.value.slice(0,this.maxLength)"
                  :maxlength="
                    field.value == 'SequenceNo'
                      ? '9'
                      : field.value == 'substep_descr'
                      ? '119'
                      : '50'
                  "
                  style="padding: 5px"
                  class="text-center ma-0"
                  label=""
                  v-model="itemData[field.value]"
                  :rules="field.rules ? [rules[field.rules]] : []"
                  outlined
                  dense
                  single-line
                >
                </v-text-field>
              </template>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: ["itemData", "show", "paperRows"],
  data() {
    return {
      formValid: true,
      containerStatusItem: {},
      totalOfPaperRowsDefault: 2,
      rules: {
        required: (value) => !!value || "Required.",
        number: (value) => {
          const pattern = /^\d+$/;

          if (`${value}`.length)
            return pattern.test(`${value}`) || "Only numbers";
          else return "Required.";
        },
      },
      itemsParentStatus: [
        { name: "INBOUND", id: 5 },
        { name: "IN-CY", id: 6 },
        { name: "SELL", id: 1 },
        { name: "OUT", id: 7 },
        { name: "SOLD", id: 3 },
      ],
      selectParentStatus: { name: "INBOUND", id: 5 },
      itemsMode: ["Terminal", "Rail", "Truck", "Depot-CY"],
      selectMode: "",
    };
  },
  watch: {
    formValid() {
      this.$emit("saveEnable", this.formValid);
    },
  },
  mounted() {
    this.selectParentStatus = {
      name: this.itemData["step_descr"],
      id: this.itemData["step_id"],
    };

    this.selectMode = this.itemData["Mode"];

    this.$emit("saveEnable", this.formValid);
  },
  methods: {
    selectChanged(val) {
      this.itemData["step_id"] = val.id;
      this.itemData["step_descr"] = val.name;
    },

    selectChangedMode(val) {
      this.itemData["Mode"] = val;
    },
  },
};
</script>

<style scoped>
.parent-status-dropdown >>> .v-select__selections {
  justify-content: left !important;
}
.v-text-field--outlined >>> fieldset {
  border-color: #3284f4;
}
.buyer-form {
  background-color: #1e1e1e;
}

.row-paper {
  flex-direction: column;
}
</style>
