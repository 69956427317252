<template>
  <v-form ref="DepotTerminationForm">
    <v-row class="ma-0 pa-0" v-for="(DataRow, index) in paperRows" :key="index">
      <v-col class="ma-0 pa-2 pt-3 pb-0">
        <v-card
          class="ma-auto text-end rounded-0 elevation-5"
          outlined
          color="#20212E"
        >
          <div
            class="text-overline ma-0 pr-4"
            v-show="paperRows.length == totalOfPaperRowsDefault"
          >
            {{ DataRow.title }}
          </div>

          <v-row class="ma-0 pa-0 row-paper">
            <v-col
              class="ma-0 pa-0"
              v-for="(field, index) in DataRow.fields"
              :key="index"
            >
              <ValidationProvider
                :rules="PassthroughAllValue ? field.rules : 'required'"
                :name="field.title"
                validate-on="change"
                v-slot="{ errors, valid }"
              >
                <v-text-field
                  class="text-center ma-0"
                  type="text"
                  v-model="itemData[field.value]"
                  outlined
                  v-if="field.type === 'Text'"
                  :error-messages="errors[0]"
                  dense
                >
                  <template v-slot:label>
                    {{ field.title }}
                  </template>
                </v-text-field>
                <div v-else-if="field.type === 'Check'" class="d-flex">
                  <v-switch
                    v-model="PassthroughAllValue"
                    class="ma-0 pa-0"
                    :label="field.title"
                    dense
                    color="success"
                    :value="true"
                    :input-value="true"
                  ></v-switch>
                </div>
              </ValidationProvider>

              <DatePicker
                v-model="itemData[field.value]"
                :data="itemData"
                :keyData="field.value"
                title="Effective From"
                :existingModel="itemData[field.value]"
                v-if="
                  field.type === 'DatePicker' && field.value === 'EffectiveFrom'
                "
              ></DatePicker>

              <DatePicker
                v-if="
                  field.type === 'DatePicker' && field.value === 'EffectiveTo'
                "
                v-model="itemData[field.value]"
                :data="itemData"
                :keyData="field.value"
                title="Effective To"
                :existingModel="itemData[field.value]"
              ></DatePicker>
              <!-- </template>
              </v-menu> -->
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import DatePicker from "@/components/General/DatePickerGeneral.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  props: ["itemData", "show", "paperRows"],
  components: { DatePicker, ValidationProvider },
  computed: {
    PassthroughAllValue: {
      get() {
        return this.itemData["PassthroughAll"] === "Y" ? true : false;
      },
      set(newValue) {
        this.itemData["PassthroughAll"] = newValue ? "Y" : "N";
      },
    },
  },
  data() {
    return {
      formValid: true,
      depotTerminationItem: {},
      totalOfPaperRowsDefault: 2,

      // rules: {
      //   required: (value) => !!value || "Required.",
      //   number: (value) => {
      //     const pattern = /^\d+$/;

      //     if (`${value}`.length)
      //       return pattern.test(`${value}`) || "Only numbers";
      //     else return "Required.";
      //   },
      // },
    };
  },
  // watch: {
  //   formValid() {
  //     this.$emit("saveEnable", this.formValid);
  //   },
  // },
  // mounted() {
  //   this.$emit("saveEnable", this.formValid);
  // },
  methods: {
    changePassThru() {
      this.itemData["PassthroughAll"] =
        this.itemData["PassthroughAll"] === "Y" ? "N" : "Y";
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #3284f4;
}
.buyer-form {
  background-color: #1e1e1e;
}

.row-paper {
  flex-direction: column;
}
</style>
