<template>
  <modal width="30%" v-if="show" :show="show" :title="title">
    <template v-slot>
      <ContainerStatus-form
        :itemData="itemData"
        :show="show"
        :paperRows="paperRows"
        @sendForm="sendForm"
        @saveEnable="saveEnable"
      ></ContainerStatus-form>
    </template>

    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-row class="ma-0 pa-0">
        <v-col class="ma-1 pa-0">
          <v-btn
            class="elevation-5"
            color="primary"
            text
            :disabled="!validate"
            @click="sendForm"
            block
          >
            Save
          </v-btn>
        </v-col>
        <v-col class="ma-1 pa-0">
          <v-btn
            class="elevation-5"
            color="primary"
            text
            @click="closeModal"
            block
          >
            Close
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </modal>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import ContainerStatusForm from "@/components/ContainerStatus/ContainerStatusForm.vue";

export default {
  components: {
    Modal,
    ContainerStatusForm,
  },
  props: ["show", "itemData", "title", "paperRows"],
  data() {
    return {
      validate: false,
    };
  },
  methods: {
    saveEnable(flag) {
      this.validate = flag;
    },
    sendForm() {
      this.closeModal();

      if (this.itemData.step_id.hasOwnProperty("id")) {
        this.itemData.step_id = this.itemData.step_id.id;
      }

      // console.log("this.itemData", this.itemData);

      this.$emit("saveContainerStatus", {
        new: this.itemData.id ? false : true,
      });

      this.validate = false;
    },
    closeModal() {
      this.$emit("showModal");
    },
  },
};
</script>

<style scoped>
button {
  border: thin #20212e solid;
}
</style>
