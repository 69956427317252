<template>
  <!-- <section> -->
  <v-footer class="py-1" dark app inset>
    <span class="hide-color overline">V{{ packageVersion }}</span>
    <span class="ml-auto overline">Nereus &copy;{{ year }}</span>
  </v-footer>
  <!-- </section> -->
</template>

<style scoped>
.hide-color {
  color: white;
}
</style>

<script>
export default {
  name: "Footer",
  computed: {
    //page year
    year() {
      return new Date().getFullYear();
    },
  },
  data() {
    return {
      packageVersion: process.env.PACKAGE_VERSION || "0",
    };
  },
};
</script>
