<template>
  <v-container class="elevation-5 dark-blue" fluid>
    <v-row dense class="justify-end align-center">
      <v-col cols="7" class="mr-auto ml-4">
        <v-text-field
          class="text-center ma-1 search-text"
          hide-details
          type="text"
          v-model="searchDepotTermination"
          @input="changeSearchValue"
          outlined
          dense
          single-line
          :prepend-inner-icon="'mdi-magnify'"
        >
          <template v-slot:label> Search </template>
        </v-text-field>
      </v-col>
      <v-col cols="2" class="col-ajust pa-2">
        <v-btn
          color="#20212E"
          class="lighten-2 elevation-5"
          @click="refresh"
          block
        >
          <v-icon>mdi-cached</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="2" class="d-flex mr-5">
        <v-btn color="#20212E" class="lighten-2 elevation-5" @click="add" block>
          <span>Add <v-icon dark>mdi-plus</v-icon></span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return { searchDepotTermination: "" };
  },
  methods: {
    ...mapActions(["getDepotTerminations"]),

    async refresh() {
      await this.getDepotTerminations();
    },
    add() {
      this.$emit("addDepotTermination");
    },
    changeSearchValue() {
      this.$emit("changeSearchValue", this.searchDepotTermination);
    },
  },
};
</script>

<style scoped>
.panel-buyer {
  background-color: #1e1e1e;
}

.panel-buyer .search-text {
  background-color: #20212e;
}
.panel-buyer button {
  border: solid thin #262737 !important;

  color: #3284f4 !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: #3284f4 !important;
}
</style>
