<template>
  <v-form ref="DepotTerminationForm">
    <v-row class="ma-0 pa-0" v-for="(DataRow, index) in paperRows" :key="index">
      <v-col class="ma-0 pa-2 pt-3 pb-0">
        <v-card class="ma-auto text-end rounded-0 elevation-5" outlined color="#20212E">
          <div
            class="text-overline ma-0 pr-4"
            v-show="paperRows.length == totalOfPaperRowsDefault"
          >
            {{ DataRow.title }}
          </div>

          <v-row class="ma-0 pa-0 row-paper">
            <v-col
              class="ma-0 pa-0"
              v-for="(field, index) in DataRow.fields"
              :key="index"
            >
              <ValidationProvider
                :rules="field.rules"
                :name="field.title"
                validate-on="change"
                v-slot="{ errors }"
              >
                <v-text-field
                  class="text-center ma-0"
                  type="text"
                  v-model="itemData[field.value]"
                  outlined
                  v-if="field.type === 'Text'"
                  :error-messages="errors[0]"
                  dense
                >
                  <template v-slot:label>
                    {{ field.title }}
                  </template>
                </v-text-field>
                <div v-else-if="field.type === 'Check'" class="d-flex">
                  <v-switch
                    v-model="PassthroughAllValue"
                    class="ma-0 pa-0"
                    :label="field.title"
                    dense
                    color="success"
                    :value="true"
                    :input-value="true"
                  ></v-switch>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>
          <!-- <BaseSpanLine :title="`Status ${status}`" />
          <v-row>
            <v-col>
              <v-row
                v-if="title.startsWith('Update')"
                class="pa-2 mx-5 my-2 elevation-2"
              >
                <v-col class="d-flex align-center justify-center text-h6">
                  <span>{{ `Status:   ${status}` }}</span>
                </v-col>
              </v-row>

              <DatePicker
                v-model="statusData.EffectiveFrom"
                :data="statusData"
                keyData="EffectiveFrom"
                title="* Date From"
                :existingModel="statusData.EffectiveFrom"
                toFormat="yyyy-LL-dd"
                rules="required"
              ></DatePicker>

              <DatePicker
                v-model="statusData.EffectiveTo"
                :data="statusData"
                keyData="EffectiveTo"
                title="Date To"
                :existingModel="statusData.EffectiveTo"
                toFormat="yyyy-LL-dd"
                :rules="`to_date:${statusData.EffectiveFrom}`"
              ></DatePicker>
            </v-col>
          </v-row> -->
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import DatePicker from "@/components/General/DatePickerGeneral.vue";
import { ValidationProvider } from "vee-validate";
import BaseSpanLine from "@/components/General/BaseSpanLine.vue";
import _ from "lodash";
import { parseFromIsoToFormat } from "@/helpers/mocks/dates.js";
export default {
  props: ["itemData", "show", "paperRows", "manageDepot", "statusData", "title"],
  components: { DatePicker, ValidationProvider, BaseSpanLine },
  created() {
    this.statusDataCopy = { ...this.statusData };
  },
  computed: {
    PassthroughAllValue: {
      get() {
        return this.itemData["PassthroughAll"] === "Y" ? true : false;
      },
      set(newValue) {
        this.itemData["PassthroughAll"] = newValue ? "Y" : "N";
      },
    },
    status() {
      return _.capitalize(this.itemData.Depot_Status);
    },
  },
  data() {
    return {
      formValid: true,
      depotTerminationItem: {},
      totalOfPaperRowsDefault: 2,
      statusValues: [
        { name: "----CUSTOM DATE----", value: null },
        { name: "Active", value: true },
        { name: "Inactive", value: false },
      ],
      statusActive: null,
      statusDataCopy: null,
    };
  },
  // watch: {
  //   statusActive() {
  //     if (this.statusActive !== null) {
  //       if (this.statusActive) {
  //         // this.statusData.EffectiveTo = "";
  //         this.statusData.EffectiveTo = this.statusDataCopy.EffectiveTo;
  //         this.statusData.EffectiveFrom = parseFromIsoToFormat(
  //           new Date().toISOString(),
  //           "yyyy-LL-dd",
  //         );
  //       } else {
  //         // this.statusData.EffectiveFrom = "";
  //         this.statusData.EffectiveFrom = this.statusDataCopy.EffectiveFrom;
  //         let yesterday = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);

  //         this.statusData.EffectiveTo = parseFromIsoToFormat(
  //           yesterday.toISOString(),
  //           "yyyy-LL-dd",
  //         );
  //       }
  //     } else {
  //       this.statusData.EffectiveFrom = this.statusDataCopy.EffectiveFrom;

  //       this.statusData.EffectiveTo = this.statusDataCopy.EffectiveTo;
  //     }
  //   },
  // },
  methods: {
    changePassThru() {
      this.itemData["PassthroughAll"] =
        this.itemData["PassthroughAll"] === "Y" ? "N" : "Y";
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #3284f4;
}
.buyer-form {
  background-color: #1e1e1e;
}

.row-paper {
  flex-direction: column;
}
</style>
