<template>
  <ValidationObserver v-slot="{ invalid }">
    <modal width="30%" v-if="show" :show="show" :title="title">
      <template v-slot>
        <depot-manage-form
          :itemData="itemData"
          :statusData="statusData"
          :show="show"
          :paperRows="paperRows"
          :title="title"
          @sendForm="sendForm"
        ></depot-manage-form>
      </template>

      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-row class="ma-0 pa-0">
          <v-col class="ma-1 pa-0">
            <v-btn
              class="elevation-5"
              color="primary"
              text
              @click="sendForm"
              block
              :disabled="invalid"
            >
              Save
            </v-btn>
          </v-col>
          <v-col class="ma-1 pa-0">
            <v-btn
              class="elevation-5"
              color="primary"
              text
              @click="closeModal"
              block
            >
              Close
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </modal>
  </ValidationObserver>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import DepotManageForm from "@/components/DepotManage/DepotManageForm.vue";
import { ValidationObserver } from "vee-validate";
export default {
  components: {
    Modal,
    DepotManageForm,
    ValidationObserver,
  },
  props: ["show", "itemData", "title", "paperRows", "statusData"],
  data() {
    return {
      validate: false,
    };
  },
  methods: {
    sendForm() {
      this.closeModal();

      this.$emit("saveDepot", this.itemData.id ? false : true);
    },
    closeModal() {
      this.$emit("showModal");
    },
  },
};
</script>

<style scoped>
button {
  border: thin #20212e solid;
}
</style>
