<template>
  <v-row class="black--text mt-12" justify="center" align="center">
    <v-col cols="6" md="10" sm="12" class="py-2 elevation-5">
      <v-row>
        <v-col class="px-4 py-4" cols="12">
          <span
            class="black--text text-no-wrap text-h4 font-weight-bold text-capitalize primary--text"
            style="letter-spacing: 2px !important"
          >
            {{ list ? "Update List" : "Create List" }}
          </span>
        </v-col>
      </v-row>
      <v-row v-for="{ label, value, type } of UI" :key="value" class="text-h6 py-2 white">
        <v-col
          cols="12"
          class="px-5 font-weight-bold text-capitalize d-flex align-center primary--text"
        >
          <span class="text-primary"> {{ label }}: </span>
        </v-col>
        <v-col cols="12" class="px-5 d-flex align-center">
          <v-text-field
            v-if="type === 'text'"
            class="ma-0 rounded-r-0 pa-0"
            hide-details
            type="text"
            light
            dense
            solo
            single-line
            v-model="obj[value]"
          >
          </v-text-field>
          <v-row v-else>
            <v-col cols="12">
              <v-text-field
                class="ma-0 rounded-lg pa-0"
                hide-details
                type="text"
                outlined
                light
                dense
                clearable
                single-line
                v-model="searchInput"
                :prepend-inner-icon="'mdi-magnify'"
                @click:clear="searchInput = ''"
              >
                <template v-slot:label><span class="pa-0 ma-0"> Search </span> </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" class="py-2 text-justify">
              <v-chip
                v-for="{ organization_descr, organization_id } in obj.customers"
                :key="organization_id"
                color="primary"
                small
                close
                class="ma-1"
                @click:close="desSelectCustomer(organization_id)"
                >{{ organization_descr }}
              </v-chip>
            </v-col>
            <v-col cols="12">
              <v-data-table
                dense
                item-key="organization_id"
                light
                hide-default-footer
                class="elevation-5 rounded-0 pa-0 ma-0 overflow-y"
                height="50vh"
                style="width: 100%"
                fixed-header
                show-select
                :loading="loading"
                :headers="customerHeader"
                :items="customersRows"
                :items-per-page="-1"
                v-model="obj.customers"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="px-5 py-1">
          <v-btn block color="primary" light @click="createList">{{
            `${list ? "Update" : "Save"}`
          }}</v-btn>
        </v-col>
        <v-col class="px-5 py-1">
          <v-btn block light @click="returnToList">Return</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { generateHeaderData } from "@/utils/tables.js";
import { mapActions, mapGetters } from "vuex";
import { search } from "@/utils/panelAction.js";
const UI = [
  { label: "List Name", value: "name", type: "text" },
  { label: "Customers", value: "list", type: "table" },
];
export default {
  props: ["list", "loading"],
  async created() {},
  data() {
    return {
      UI,
      // customersSelected: [],
      obj: { name: "", customers: [] },
      // loading: false,
      searchInput: "",
    };
  },
  watch: {
    list: {
      handler() {
        this.obj = { ...this.list };
      },
    },
  },
  computed: {
    ...mapGetters({
      filteredListCustomers: "moduleOrganizations/filteredListCustomers",
    }),
    customersList() {
      return this.obj.customers.map((x) => {
        return { customerId: x.organization_id };
      });
    },
    customersRows() {
      return search({
        searchInput: this.searchInput,
        header: this.customerHeader,
        arr: this.filteredListCustomers,
      });
      // if (this.list) {
      //   let org_ids = this.obj.customers.map((x) => Number(x.organization_id));
      //   let filterdCustomers = filteredListCustomers.filter(
      //     (x) => !org_ids.includes(x.organization_id),
      //   );

      //   return [...this.obj.customers, ...filterdCustomers];
      // }
      // return filteredListCustomers;
    },
    customerHeader() {
      const HEADER = [
        {
          text: "Name of list",
          value: "organization_descr",
        },
      ];
      return generateHeaderData(HEADER);
    },
  },
  methods: {
    ...mapActions({
      getsOrganizations: "moduleOrganizations/getsOrganizations",
      postList: "moduleCustomerDistribution/postList",
      editList: "moduleCustomerDistribution/editList",
    }),
    desSelectCustomer(id) {
      let customers = this.obj.customers.filter((x) => x.organization_id != id);
      this.obj.customers = customers;
    },
    async createList() {
      let obj = { name: this.obj.name, customers: this.customersList };
      if (this.list) {
        obj.id = this.list.id;
        await this.editList(obj);
      } else await this.postList(obj);

      setTimeout(() => {
        this.returnToList();
      }, 2000);
    },
    returnToList() {
      this.$router.push("/customerlist");
    },
  },
};
</script>

<style></style>
