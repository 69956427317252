<template>
  <ValidationObserver v-slot="{ invalid }">
    <modal width="30%" v-if="show" :show="show" :title="title">
      <template v-slot>
        <DepotTermination-form
          :itemData="itemData"
          :show="show"
          :paperRows="paperRows"
          @sendForm="sendForm"
        ></DepotTermination-form>
      </template>

      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-row class="ma-0 pa-0">
          <v-col class="ma-1 pa-0">
            <v-btn
              class="elevation-5"
              color="primary"
              text
              :disabled="invalid"
              @click="sendForm"
              block
            >
              Save
            </v-btn>
          </v-col>
          <v-col class="ma-1 pa-0">
            <v-btn
              class="elevation-5"
              color="primary"
              text
              @click="closeModal"
              block
            >
              Close
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </modal>
  </ValidationObserver>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import DepotTerminationForm from "@/components/DepotTermination/DepotTerminationForm.vue";
import { ValidationObserver } from "vee-validate";
export default {
  components: {
    Modal,
    DepotTerminationForm,
    ValidationObserver,
  },
  props: ["show", "itemData", "title", "paperRows"],
  data() {
    return {
      validate: false,
    };
  },
  methods: {
    // saveEnable(flag) {
    //   this.validate = flag;
    // },
    sendForm() {
      this.closeModal();
      this.$emit("saveDepotTermination", {
        new: this.itemData.id ? false : true,
      });
    },
    closeModal() {
      this.$emit("showModal");
    },
  },
};
</script>

<style scoped>
button {
  border: thin #20212e solid;
}
</style>
