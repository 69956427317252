<template>
  <div>
    <span class="text-h5 font-weight-medium">Contacts</span>
    <v-data-table
      hide-default-footer
      disable-pagination
      dense
      :headers="headerExpanded"
      :items="item.contacts"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-row>
          <v-col>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="showModal(item, 'Update Contact')"
                  color="#20212E"
                  class="lighten-2 rounded-0 elevation-5"
                  block
                  icon
                >
                  <v-icon dark>mdi-account-edit</v-icon>
                </v-btn>
              </template>
              <span>Edit Contact</span>
            </v-tooltip>
          </v-col>
          <v-col>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteItem(item)"
                  color="#20212E"
                  class="lighten-2 rounded-0 elevation-5"
                  block
                  icon
                >
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete Contact</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { generateHeaderData } from "@/utils/tables.js";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  props: ["item"],
  computed: {
    ...mapState(["depotManage"]),
    headerExpanded() {
      return generateHeaderData([
        { text: "Name", value: "contact_name" },
        { text: "Title", value: "contact_title" },
        { text: "Email", value: "contact_email" },
        { text: "Phone", value: "contact_phone" },
        { text: "", value: "actions", width: "100px" },
      ]);
    },
  },
  methods: {
    ...mapActions({
      getContacts: "moduleDepotManage/getContacts",
      deleteContact: "moduleDepotManage/deleteContact",
    }),
    ...mapMutations({
      setContactDepotManage: "setContactDepotManage",
    }),

    showModal(item, title) {
      this.$emit("showModal", { item, title });
    },
    async deleteItem(item) {
      await this.deleteContact(item);
      if (this.item.hasOwnProperty("contacts")) {
        let res = await this.getContacts(this.item);
        let index = this.depotManage.findIndex(
          (x) => x.DepotId === this.item.DepotId,
        );

        this.setContactDepotManage({ index, newState: res });
      }
    },
  },
};
</script>

<style></style>
